.ns-list {
    &__item {
        position: relative;
        padding-left: 16px;
        font-size: 14px;
        line-height: 140%;
        color: var(--t-primery-c);

        &:not(:last-child) {
            margin-bottom: 5px;
        }

        &:before {
            content: '';
            position: absolute;
            top: 5px;
            left: 0;
            width: 8px;
            height: 8px;
            background-color: var(--s-brand-c);
            border-radius: 50%;
        }
    }

    &__text {
        &--bold {
            font-size: 16px;
            font-weight: 700;
        }
    }


    &--in-block {
        .ns-list__item {
            font-size: 17px;
            line-height: 160%;

            &:before {
                top: 12px;
                width: 4px;
                height: 4px;
                background-color: var(--s-secondary-c-1);
            }
        }
    }
}
