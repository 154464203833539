.ns-search {
    padding-bottom: 104px;

    &__link-wrapper {
        display: inline-block;

        @include before_480 {
            display: none;
        }
    }

    &__example-text {
        @include before_768 {
            display: none;
        }
    }

    &__link {
        text-decoration: none;
        margin-left: 24px;
    }

    // &__link:not(:last-child) {
    //     margin-: 0 24px;
    // }
}
