@import '../../../assets/global-style/mixin';

.ns-companyNews {
    padding-bottom: 104px;

    &__title {
        margin-bottom: 40px;
    }

    &__cards-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;

        margin-bottom: 24px;
    }

    &__card {
        backdrop-filter: blur(26px);
        border: 1px solid var(--color-white);
        background: var(--color-rgba255);
        box-shadow: 4px 15px 24px -8px rgba(73, 75, 89, 0.04);
        border-radius: 24px;
        padding: 16px;
        height: 149px;
    }

    &__card-date {
        margin-bottom: 12px;
    }

    &__button {
        margin: 0 auto;
    }

    &__slider {
        padding-bottom: 0 !important;
        margin-bottom: 24px;

        @include before_1024 {
            padding-bottom: 50px !important;
            margin-bottom: 0;
        }
    }

    &__slider-wrapper {
        gap: 24px;

        @include before_1024 {
            gap: 0;
        }
    }

    &__slide {
        flex-shrink: unset;

        @include before_1024 {
            flex-shrink: 0;
        }
    }

    &__pagination {
        display: none !important;
        justify-content: center;
        align-items: center;

        @include before_1024 {
            display: flex !important;
        }
    }
}
