.ns-advantages {
    padding-bottom: 104px;
	@include before_768 {
		padding-bottom: 80px;
	}
	@include before_550 {
		padding-bottom: 48px;
	}
    &__title {
        margin-bottom: 40px;
    }

    &__card-wrapper {
		padding-bottom: 104px;
		display: flex;
        gap: 24px;
		@include before_768 {
			padding-bottom: 80px;
		}
		@include before_550 {
			padding-bottom: 48px;
			flex-direction: column;
		}
    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        backdrop-filter: blur(26px);
        border: 1px solid var(--color-white);
        background: var(--color-rgba255);
        box-shadow: 4px 15px 24px -8px rgba(73, 75, 89, 0.04);
        border-radius: 24px;
        width: 33.3%;
        height: 253px;

        @include before_768 {
            width: 50%;
        }

        @include before_550 {
            justify-content: flex-start;
            flex-direction: row;
            width: 100%;
            height: 110px;
            background: none;
            border: none;
        }
    }

    &__icon {
        margin-bottom: 32px;

        @include before_550 {
            display: flex;
            justify-content: center;
            align-items: center;

            background: var(--color-rgba255);
            backdrop-filter: blur(26px);
            border: 1px solid;
            border-image-source: linear-gradient(102.69deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.2) 64.86%);
            border-radius: 24px;

            margin-bottom: 0;
            margin-right: 16px;
            height: 100%;
            width: 110px;
        }
    }

    &__content {
        text-align: center;
    }
}
