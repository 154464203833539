@import '../../components/other/NBanner/banner';
@import '../../components/other/NInsurance/insurance';
@import '../../components/other/NAdvantages/advantages';
@import '../../components/other/NCompanyNews/companyNews';
@import '../../components/other/NSearch/search';
@import '../../components/other/NMenu/menu';
@import '../../components/other/NList/list';
@import '../../components/images/Icon/icon';

.ns-insurance__img-box-mobile {
    display: none;
    @include before_1024 {
        display: flex;
    }
}

.ns-insurance__img-box-desktop {
    display: flex;
    @include before_1024 {
        display: none;
    }
}

.mobile-img {
    display: none;
    @include before_1024 {
        display: flex;
    }
}

body {
    overflow-x: hidden;
}
.ns-insurance__program {
    &-title {
        margin-bottom: 16px;
        @include before_550 {
            margin-bottom: 8px;
        }
    }
    &-subtitle {
        margin-bottom: 16px;

        &-ny {
            margin-bottom: 30px;
            @include before_550 {
                margin-bottom: 21px;
            }
        }
    }
    .ns-title_m-b {
        margin-bottom: 16px;
    }
    @include before_668 {
        .ns-insurance__program-subtitle {
            margin-bottom: 24px;
        }
        .ns-title_m-b {
            margin-bottom: 42px;
        }
    }
}

// .ns-banner__img-mobile {
//     display: none !important;
//     @include before_550 {
//         display: flex !important;
//         margin-bottom: 24px;
//     }
// }

// .ns-banner__img-desktop {
//     display: flex !important;
//     @include before_550 {
//         display: none !important;
//     }
// }
.swiper {
    padding-bottom: 50px;
}

.swiper-pagination {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 0px;
    bottom: 20px !important;
}

.swiper-pagination-bullet {
    width: 19px;
    height: 19px;
    background: transparent !important;
    opacity: 1 !important;
}

.swiper-pagination-bullet-active .path {
    display: inline-block !important;
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    animation: dash linear 120s;
    animation-iteration-count: unset;
}

.swiper-pagination-bullet-active .inner-circle {
    fill: var(--color-green2);
}

.path {
    display: none;
}

.path:not(:last-child) {
    margin-right: 12px;
}

@keyframes dash {
    from {
        stroke-dashoffset: 1000;
    }

    to {
        stroke-dashoffset: 0;
    }
}

.swiper-slide {
    height: 100%;
}
.swiper-container {
    height: 100%; /* Высота контейнера */
}

.news-blocks {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;

    @include before_1200 {
        grid-template-columns: 1fr;
    }
    &__item {
        padding: 24px;
        backdrop-filter: blur(26px);
        border: 1px solid var(--color-white);
        background: var(--color-rgba255);
        box-shadow: 4px 15px 24px -8px rgba(73, 75, 89, 0.04);
        border-radius: 24px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        @include before_1200 {
            width: 100%;
            max-width: none;
        }
    }
}
.show-more-news {
    display: flex;
    justify-content: center;
    margin-top: 24px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fade-in {
    animation: fadeIn 1s;
}

// .swiper-pagination {
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

// .swiper-pagination-bullet {
//     background: transparent;
//     opacity: 1 !important;
//     border: 1px solid var(--color-green2);
// }

// .swiper-pagination-bullet-active {
//     position: relative;
//     background: transparent;
//     border: 1px solid var(--color-green2);
//     opacity: 1 !important;
//     width: 16px !important;
//     height: 16px !important;
// }

// .swiper-pagination-bullet-active::before {
//     content: '';
//     position: absolute;
//     left: 0;
//     right: 0;
//     top: 0;
//     bottom: 0;
//     margin: auto;
//     display: block;
//     width: 10px;
//     height: 10px;
//     -moz-border-radius: 7.5px;
//     -webkit-border-radius: 7.5px;
//     border-radius: 7.5px;
//     background-color: var(--color-green2);
// }
