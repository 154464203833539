@import '../../../assets/global-style/mixin';
.mt-33 {
	margin-top: 33px;
}
.ns-insurance {
	padding-bottom: 104px;
	@include before_768 {
		padding-bottom: 80px;
	}
	@include before_550 {
		padding-bottom: 48px;
	}
	&--pt60 {
		padding-top: 60px;
	}

	&__title {
		margin-bottom: 10px;
	}

	&__programs-wrapper {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-areas:
            'program-1 program-2'
            'program-1 program-3'
            'program-4 program-4';
		gap: 32px;

		//@include before_1200 {
		//    grid-template-columns: 1fr;
		//}

		@include before_1024 {
			display: flex;
			flex-direction: column;
		}

		&--area2 {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-areas:
                'program-1 program-2'
                'program-3 program-3'
                'program-4 program-5';
			gap: 32px;

			@include before_1024 {
				display: flex;
				flex-direction: column;
			}
		}

		&--area3 {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			// grid-template-areas: unset;
			grid-template-areas:
                'program-2 program-2'
                'program-1 program-5'
                'program-3 program-3';
			gap: 32px;

			//@include before_1200 {
			//    grid-template-columns: 1fr;
			//}

			@include before_1024 {
				display: flex;
				flex-direction: column;
			}
		}
		&--area4 {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			// grid-template-areas: unset;
			grid-template-areas: 'program-1 program-2';
			gap: 32px;

			//@include before_1200 {
			//    grid-template-columns: 1fr;
			//}

			@include before_1024 {
				display: flex;
				flex-direction: column;
			}
		}
		&--area5 {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			// grid-template-areas: unset;
			grid-template-areas:
				'program-10 program-10'
                'program-1 program-2'
                'program-1 program-2'
                'program-3 program-3'
                'program-7 program-6'
                'program-7 program-6'
                'program-8 program-8'
                'program-4 program-5'
                'program-4 program-5'
                'program-9 program-9';
			gap: 32px;
			margin-bottom: 32px;

			//@include before_1200 {
			//    grid-template-columns: 1fr;
			//}

			@include before_1024 {
				display: flex;
				flex-direction: column;
			}
		}
		&--area6 {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			// grid-template-areas: unset;
			grid-template-areas:
                'program-1 program-2'
                'program-1 program-2'
                'program-9 program-9';
			gap: 32px;

			//@include before_1200 {
			//    grid-template-columns: 1fr;
			//}

			@include before_1024 {
				display: flex;
				flex-direction: column;
			}
		}
		&--area7 {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			// grid-template-areas: unset;
			grid-template-areas: 'program-1 program-2 program-3';
			gap: 32px;

			//@include before_1200 {
			//    grid-template-columns: 1fr;
			//}

			@include before_1024 {
				display: flex;
				flex-direction: column;
			}
		}
		&--area8 {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			// grid-template-areas: unset;
			grid-template-areas: 'program-1 program-2';
			gap: 32px;

			//@include before_1200 {
			//    grid-template-columns: 1fr;
			//}

			@include before_1024 {
				display: flex;
				flex-direction: column;
			}
		}

		&--mb32 {
			margin-bottom: 32px;
		}
	}
	&__programs-wrapper-2 {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-areas:
            'program-1 program-2'
            'program-1 program-3';
		gap: 32px;
		margin-bottom: 32px;

		//@include before_1200 {
		//    grid-template-columns: 1fr;
		//}

		@include before_1024 {
			display: flex;
			flex-direction: column;
		}

		&--area2 {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-areas:
                'program-1 program-2'
                'program-3 program-3'
                'program-4 program-5';
			gap: 32px;

			@include before_1024 {
				display: flex;
				flex-direction: column;
			}
		}

		&--area3 {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			// grid-template-areas: unset;
			grid-template-areas:
                'program-1 program-5'
                'program-3 program-3';
			gap: 32px;

			//@include before_1200 {
			//    grid-template-columns: 1fr;
			//}

			@include before_1024 {
				display: flex;
				flex-direction: column;
			}
		}
		&--area4 {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			// grid-template-areas: unset;
			grid-template-areas:
                'program-8 program-2'
                'program-8 program-2'
                'program-1 program-3'
                'program-1 program-3'
                'program-6 program-9'
                'program-6 program-9'
                'program-4 program-4';
			gap: 32px;

			//@include before_1200 {
			//    grid-template-columns: 1fr;
			//}

			@include before_1024 {
				display: flex;
				flex-direction: column;
			}
		}
	}

	&__flex-wrapper {
		display: flex;
		justify-content: space-between;

		@include before_1024 {
			display: block;
		}
	}

	&__programs-wrapper:first-child {
		margin-bottom: 32px;
	}
	&__programs-wrapper:nth-child(2) {
		@include before_1024 {
			margin-bottom: 32px;
		}
	}

	&__more-button {
		margin: 8px auto auto auto;
	}

	&__more-icon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 109px;
		height: 109px;
		border-radius: 50%;

		background: linear-gradient(102.69deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.2) 64.86%),
		linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3));
		margin-top: 8px;

		@include before_550 {
			width: 64px;
			height: 64px;
		}
	}

	&__program-1 {
		grid-area: program-1;
		justify-content: space-between;
	}

	&__program-2 {
		grid-area: program-2;
		&__video {
			padding: 0 !important;
			border-radius: 25px !important;
			video {
				border-radius: 25px !important;
			}
		}
	}

	&__program-3 {
		grid-area: program-3;
	}

	&__program-4 {
		grid-area: program-4;

		@include before_1024 {
			justify-content: space-between;
		}
	}

	&__program-5 {
		grid-area: program-5;
		justify-content: space-between;
	}
	&__program-6 {
		grid-area: program-6;
	}

	&__program-7 {
		grid-area: program-7;
	}

	&__program-8 {
		grid-area: program-8;
	}

	&__program-9 {
		grid-area: program-9;
	}

	&__program-10 {
		grid-area: program-10;
	}

	&__program {
		position: relative;
		display: flex;
		flex-direction: column;
		padding: 56px 50px 32px;
		justify-content: space-between;
		backdrop-filter: blur(26px);
		border: 1px solid var(--s-inverted-c);
		background: var(--s-secondary-c-4);
		box-shadow: 4px 15px 24px -8px rgba(73, 75, 89, 0.04);
		border-radius: 24px;
		height: auto;
		min-height: 234px;

		&__h33 {
			height: 330px;
		}
		&__min-h6 {
			min-height: 598px;
		}

		&__banner {
			flex-direction: row;
			@include before_1024 {
				flex-direction: column;
			}
		}

		&__content {
			width: 50%;
			@include before_1024 {
				width: 100%;
			}
			.ns-insurance__program-title {
				margin-bottom: 24px;
			}
			.ns-insurance__program-subtitle {
				margin-bottom: 24px;
			}
		}

		&--background {
			background: url('../../assets/img/pages/banner-img-3.png');
			background-color: var(--s-secondary-c-4);
			background-repeat: no-repeat;
			background-size: cover;
			@include before_1024 {
				min-height: 410px;
			}
			@include before_968 {
				gap: 200px;
			}
		}

		&--background-2 {
			background: url('../../assets/img/pages/banner-img-50.png');
			background-color: var(--s-secondary-c-4);
			background-repeat: no-repeat;
			background-position: bottom;
			@include before_1024 {
				min-height: 410px;
			}
			@include before_668 {
				gap: 200px;
			}
		}

		&--background-3 {
			background: url('../../assets/img/pages/banner-img-51.png');
			background-color: var(--s-secondary-c-4);
			background-repeat: no-repeat;
			background-position: bottom;
			@include before_1024 {
				min-height: 410px;
			}
			@include before_668 {
				gap: 200px;
			}
		}

		&--absolute {
			img {
				position: absolute;
				top: unset;
				bottom: 0;
				right: 3px;
				margin: auto 0;
				max-height: 220px !important;

				@include before_1024 {
					position: static;
					margin: 0 auto;
					max-height: 300px !important;
				}
			}
		}
		&--absolute2 {
			img {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 56px;
				margin: auto 0;
				max-height: 276px !important;

				@include before_1024 {
					position: static;
					margin: 0 auto;
				}
			}
		}

		&--absolute3 {
			img {
				position: absolute;
				bottom: 0;
				right: 76px;
				margin: auto 0;
				max-height: 265px !important;

				@include before_1024 {
					position: static;
					margin: 0 auto;
					max-height: 236px !important;
					right: 60px;
				}
			}
		}
		&--absolute-family {
			img {
				position: absolute;
				bottom: 0;
				right: 56px;
				max-height: 210px;

				@include before_1024 {
					position: static;
					margin: 0 auto;
				}
			}
		}

		&--absolute-ny {
			img {
				position: absolute;
				top: 0;
				right: 6px;
				margin: auto 0;
				max-height: 100% !important;

				@include before_1024 {
					position: static;
					margin: 0;
				}
			}
		}


		&--between {
			justify-content: space-between;
		}

		&--w604 {
			height: 604px;

			@include before_480 {
				height: 432px;
			}
		}
		&--w168 {
			height: 168px;

			@include before_480 {
				height: 432px;
			}
		}

		&--w345 {
			height: 345px;

			// @include before_480 {
			//     height: 432px;
			// }
		}
		@include before_1024 {
			min-height: fit-content;
		}
		@include before_550 {
			padding: 16px;
			justify-content: space-between;
		}
	}

	&__program-content {
		z-index: 30;
	}

	&__img-box {
		display: flex;
		&__absolute {
			position: absolute;
			bottom: 0;
			@include before_1024 {
				position: relative;
				margin: auto;
			}
		}

		&__absolute2 {
			position: absolute;
			bottom: 0;
			right: 0;
			margin: auto;
			@include before_1024 {
				position: relative;
				margin: 0 auto -66px 0 !important;
			}
		}

		&__absolute3 {

			@include before_1024 {
				position: relative;
				margin: 0 auto -66px 0 !important;
			}
		}
		img {
			max-height: 400px;
		}
		.img2 {
			max-height: 390px;
		}
		.podushka {
			height: 440px;
			max-height: 440px;
			@include before_668 {
				height: auto;
			}
		}
	}

	&__program-subtitle {
		& a {
			color: var(--s-brand-c);
		}

		@include before_668 {
			margin-bottom: 30px;
		}
	}

	&__img-box {
		@include before_1024 {
			margin: 0 auto 40px auto;
		}

		@include before_480 {
			margin: 0 auto 26px auto;
		}

		&--ny {
			order: -1;

			@include before_1024 {
				position: relative;
  				top: -56px;
				right: -56px;
				margin: 0;
				display: flex;
    			justify-content: end;
			}

			@include before_550 {
				top: -16px;
				right: -16px;
			}
		}
	}

	&__tabs-list {
		@include before_1024 {
			align-self: unset;
			overflow-x: scroll;
			padding-bottom: 30px;
		}
	}

	&__mob-button {
		display: none !important;

		@include before_1024 {
			display: flex !important;
		}
	}

	&__desc-button {
		@include before_1024 {
			display: none !important;
		}
	}
	&__stiker {
		margin-bottom: 16px;
	}
}

