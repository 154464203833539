@import '../../../assets/global-style/mixin';

.ns-banner {
	padding-bottom: 28px;
	min-height: 338px;

	&__slide {
		height: 100%;
		padding: 48px 56px 48px 48px;
		overflow: hidden;
		border: 1px solid var(--s-inverted-c);
		background: var(--s-secondary-c-4);
		box-shadow: 4px 15px 24px -8px rgba(73, 75, 89, 0.04);
		backdrop-filter: blur(26px);
		border-radius: 24px;

		@include before_1024 {
			padding: 32px;
		}

		@include before_480 {
			padding: 16px;
		}

		&--ns {
			background: linear-gradient(77deg, #1fa65f -10.82%, #173d18 121.04%);

			.ns-banner__title,
			.ns-banner__text {
				color: var(--t-inverted-c);
			}
		}

		&--ns2 {
			background: linear-gradient(21deg, rgba(8, 166, 82, 1) 0%, rgba(23, 211, 23, 1) 54%, rgba(249, 238, 47, 1) 100%);

			.ns-banner__sticker {
				color: var(--t-primery-c);
				background: var(--s-inverted-c);
			}
			.ns-banner__title,
			.ns-banner__text,
			.ns-banner__mini-text {
				color: var(--t-inverted-c);
			}

		}
		&--ns3 {
			background: linear-gradient(45deg, rgba(33, 159, 56, 1) 7%, rgba(71, 197, 19, 1) 36%, rgba(33, 186, 114, 1) 58%, rgba(33, 186, 114, 0.14) 100%);

			.ns-banner__sticker {
				color: var(--t-primery-c);
				background: var(--s-inverted-c);
			}
			.ns-banner__title,
			.ns-banner__text,
			.ns-banner__mini-text {
				color: var(--t-inverted-c);
			}

		}

		&--ns4 {
			background-image: linear-gradient(
					45deg,
					hsl(195deg 95% 68%) 0%,
					hsl(192deg 48% 43%) 9%,
					hsl(188deg 60% 21%) 18%,
					hsl(180deg 100% 6%) 27%,
					hsl(180deg 100% 6%) 36%,
					hsl(180deg 100% 6%) 45%,
					hsl(180deg 100% 6%) 54%,
					hsl(180deg 100% 6%) 63%,
					hsl(180deg 100% 6%) 72%,
					hsl(146deg 47% 22%) 82%,
					hsl(126deg 39% 45%) 91%,
					hsl(117deg 87% 69%) 100%
			);

			.ns-banner__title,
			.ns-banner__text {
				color: var(--t-inverted-c);
			}

		}
		&--ns5 {
			background: url('../../assets/img/pages/banner-bg2.png');
			background-position: center;
			background-size: cover;
			.ns-banner__title,
			.ns-banner__text {
				color: var(--t-inverted-c);
			}
			.ns-banner__sticker {
				color: var(--t-primery-c);
				background: var(--s-inverted-c);
			}

		}

		&--ns6 {
			background: url('../../assets/img/pages/banner-bg3.png');
			background-position: center;
			background-size: cover;
			.ns-banner__title,
			.ns-banner__text {
				color: var(--t-inverted-c);
			}

		}

		&--ns7 {
			background: url('../../assets/img/pages/banner1.png');
			background-position: center;
			background-size: cover;

		}
	}

	&__grid {
		display: grid;
		grid-template-columns: 1.3fr 1fr;
		grid-template-rows: auto 1fr;
		column-gap: 24px;
		row-gap: 20px;
		height: 100%;

		@include before_1024 {
			grid-template-columns: 1fr 1fr;
		}

		@include before_768 {
			column-gap: 8px;
		}

		@include before_550 {
			grid-template-columns: 1fr;
			grid-template-rows: auto 1fr auto;
			row-gap: 26px;
		}
	}
	&__grid2 {
		display: grid;
		grid-template-columns: 1.3fr 1.4fr;
		grid-template-rows: auto 1fr;
		column-gap: 24px;
		row-gap: 20px;
		height: 100%;

		@include before_1024 {
			grid-template-columns: 1fr 1fr;
		}

		@include before_768 {
			column-gap: 8px;
		}

		@include before_550 {
			grid-template-columns: 1fr;
			grid-template-rows: auto 1fr auto;
			row-gap: 26px;
		}
	}
	&__grid3 {
		display: grid;
		grid-template-columns: 1.3fr 1.4fr;
		grid-template-rows: auto 1fr;
		column-gap: 24px;
		row-gap: 20px;
		height: 100%;

		@include before_1024 {
			grid-template-columns: 1fr 1fr;
		}

		@include before_768 {
			column-gap: 8px;
		}

		@include before_550 {
			grid-template-columns: 1fr;
			grid-template-rows: 1fr auto  auto;
			row-gap: 26px;
		}
	}
	&__grid4 {
		display: grid;
		grid-template-columns: 1.6fr 1.4fr;
		grid-template-rows: auto 1fr;
		column-gap: 24px;
		row-gap: 20px;
		height: 100%;

		@include before_1024 {
			grid-template-columns: 1fr 1fr;
		}

		@include before_768 {
			column-gap: 8px;
		}

		@include before_550 {
			grid-template-columns: 1fr;
			grid-template-rows: 1fr auto  auto;
			row-gap: 26px;
		}
	}
	&__grid5 {
		display: grid;
		grid-template-columns: 1.4fr 1.4fr;
		grid-template-rows: auto 1fr;
		column-gap: 0px;
		row-gap: 20px;
		height: 100%;

		@include before_1024 {
			grid-template-columns: 1fr 1fr;
		}

		@include before_768 {
			column-gap: 8px;
		}

		@include before_550 {
			grid-template-columns: 1fr;
			grid-template-rows: 1fr auto  auto;
			row-gap: 26px;
		}
	}

	&__info {
		position: relative;
		padding-top: 44px;

		@include before_550 {
			padding-top: 0;
		}

		&--time {
			padding-top: 0;
		}
	}
	&__info {
		position: relative;
		padding-top: 44px;

		@include before_550 {
			padding-top: 0;
		}

		&--time {
			padding-top: 0;
		}
	}

	&__info2 {
		position: relative;
		padding-top: 44px;

		@include before_550 {
			grid-row: 2;
			padding-top: 0;
		}

		&--time {
			padding-top: 0;
		}
	}

	&__sticker {
		position: absolute;
		top: 0;
		left: 0;

		@include before_1024 {
			margin-bottom: 16px;
		}

		@include before_550 {
			position: static;
			top: auto;
			left: auto;
			margin-bottom: 8px;
		}
	}

	&__title {
		margin-bottom: 8px;
		font-size: 36px;
		font-weight: 700;
		line-height: 115%;
		color: var(--t-primery-c);

		@include before_1024 {
			font-size: 32px;
		}

		@include before_768 {
			font-size: 24px;
		}

		@include before_480 {
			margin-bottom: 8px;
		}
	}

	&__title2 {
		margin-bottom: 8px;
		font-size: 44px;
		font-weight: 700;
		line-height: 115%;
		color: var(--t-primery-c);

		@include before_1024 {
			font-size: 38px;
		}

		@include before_768 {
			font-size: 32px;
		}

		@include before_480 {
			font-size: 24px;
			margin-bottom: 8px;
		}
	}
	&__title3 {
		margin-bottom: 8px;
		font-size: 36px;
		font-weight: 600;
		line-height: 115%;
		color: var(--t-primery-c);

		@include before_1024 {
			font-size: 32px;
		}

		@include before_768 {
			font-size: 24px;
		}

		@include before_480 {
			margin-bottom: 8px;
		}
	}

	&__title3 {
		margin-bottom: 8px;
		font-size: 44px;
		font-weight: 600;
		line-height: 115%;
		color: var(--t-primery-c);

		@include before_1024 {
			font-size: 38px;
		}

		@include before_768 {
			font-size: 32px;
		}

		@include before_480 {
			font-size: 24px;
			margin-bottom: 8px;
		}
	}

	&__text {
		max-width: 500px;
		font-size: 18px;
		line-height: 140%;
		color: var(--t-primery-c);

		@include before_768 {
			font-size: 16px;
			line-height: 120%;
		}

	}
	.list-block {
		gap: 8px;
		display: flex;
		flex-direction: column;
	}

	.list-block .ns-li {
		display: flex;
		flex-direction: row;
		gap: 20px;
	}
	.list-block .ns-li .list-icon {
		width: 8px;
		height: 8px;
		object-fit: contain;
		margin: auto 0;
		display: block !important;
	}

	&__text2 {
		max-width: 500px;
		font-size: 24px;
		line-height: 140%;
		color: var(--t-primery-c);

		@include before_768 {
			font-size: 18px;
			line-height: 120%;
		}
	}

	&__mini-text {
		font-size: 10px;
		font-weight: 300;
		line-height: 140%;
		color:  #657179;
		a {
			color:  #657179;
		}

		@include before_768 {
			font-size: 8px;
			line-height: 120%;
		}
	}



	&__button {
		align-self: flex-start;
		grid-row: 2/3;



		&__double {
			display: flex;
			flex-direction: row;
			gap: 24px;
			@include before_550 {
				flex-direction: column;
				gap: 16px;
			}
		}
		@include before_550 {
			grid-row: 3/4;
		}
	}

	&__img-box {
		position: relative;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		grid-column: 2/3;
		grid-row: 1/3;

		@include before_550 {
			justify-content: center;
			grid-column: 1/2;
			grid-row: 2/3;
		}

		&--bottom {
			align-items: flex-end;
			margin-bottom: -48px;

			@include before_1024 {
				margin-bottom: -32px;
			}

			@include before_768 {
				margin: 0 -32px -32px 0;
			}

			@include before_550 {
				margin: 0 -30px -110px -56px;
			}

			@include before_480 {
				margin: 0 -30px -94px -56px;
			}
		}

		&--bg {
			position: relative;
			margin: -48px -56px -48px 0;

			@include before_1024 {
				margin: -32px -32px -32px 0;
			}

			@include before_480 {
				margin: -26px -16px -26px 0;
			}
		}

		&--bg2 {
			position: relative;
			margin: 0px 0px -60px -18px;

			@include before_1024 {
				margin: 0px -18px -38px 0;
			}

			@include before_668 {
				margin: -29px auto;
				width: 100%;
			}

			@include before_480 {
				margin: 0px 0px -30px 0;
			}
		}
		&--bg3 {
			position: relative;
			margin: -85px 0px -60px -18px;


			@include before_1220 {
				margin: -118px -18px -38px 0;
			}

			@include before_1024 {
				margin: -94px -18px -38px 0;
			}

			@include before_968 {
				margin: -246px -18px -99px 0;
			}

			@include before_668 {
				margin: -309px -18px -118px 0;
				width: 100%;
			}

			@include before_550 {
				grid-row: 1;
				margin: -30px 0px 0px 0;
			}

		}
	}

	&__img {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		object-fit: contain;
		object-position: right;

		&--bg {
			bottom: 0;
			height: auto;
			top: unset;
		}

		&--bg2 {
			bottom: 0;
			top: unset;
			@include before_768 {
				right: unset;
				object-position: center;
			}
			@include before_550 {
				bottom: -20px;
				object-position: bottom;
			}
		}
		&--bg4 {
			bottom: 0;
			top: unset;
			@include before_768 {
				right: unset;
				object-position: center;
			}
			@include before_550 {
				bottom: 20px;
				object-position: bottom;
			}
		}
		&--bg3 {
			bottom: -10%;
			height: 100%;
			top: unset;
		}

		&__bottom {
			bottom: 0;
			object-position: bottom;
			@include before_550 {
				object-position: inherit;
			}
		}

		&:not(.ns-banner__img--bg, .ns-banner__img--bg2, .ns-banner__img--bg4, .ns-banner__img--ns) {
			@include before_550 {
				position: static;
			}
		}

		&--ns {
			object-position: bottom right;

			@include before_550 {
				// width: 64%;
				object-position: center bottom;
				margin: 0 auto;
			}
		}

		&--ns2 {
			object-position: bottom right;

			@include before_550 {
				width: 64%;
				margin: 0 auto;
			}
		}

		&--schedule {
			@include before_550 {
				width: 64%;
				margin: 0 auto;
			}
		}
	}

	&__note {
		font-size: 10px;
		line-height: 140%;
		color: var(--t-primery-c);
	}

	.swiper-slide {
		height: auto;
		min-height: 338px;

		@include before_768 {
			min-height: 300px;
		}
	}

	// .swiper-wrapper {
	//     height: 338px;
	// }
}
